<script>
import Vue, {
  computed,
  reactive,
  ref,
  onBeforeMount,
  onBeforeUnmount,
} from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import modal from '/~/core/mdl'
import { formatDollar } from '/~/utils/format/money'
import { roundFigure } from '/~/utils/format/numeric'
import BaseBack from '/~/components/base/back/base-back.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import PaymentMethodsList from '/~/components/payments/list/split.v3.vue'
import { useAccess } from '/~/composables/access/use-access'
import { useAddresses } from '/~/composables/addresses'
import { useCart } from '/~/composables/cart'
import { useCheckoutReactive, useCheckoutRoutes } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import {
  useVerifyCard,
  useFlowPaymentMethods,
} from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'
import { usePurchases } from '/~/composables/purchases'
import { useStatementAutoPayments } from '/~/composables/statements'
import MessagePanel from '/~/templates/bill-payments/components/message-panel.vue'
import CheckoutAcknowledgement from '/~/views/checkout.v2/components/checkout/acknowledgement.vue'
import CheckoutSelectAddress from '/~/views/checkout.v2/components/checkout/checkout-select-address.vue'
import CheckoutCoupons from '/~/views/checkout.v2/components/checkout/coupons/checkout-coupons.vue'

export default {
  name: 'checkout-desktop',
  components: {
    CheckoutCoupons,
    CheckoutSelectAddress,
    PaymentMethodsList,
    MessagePanel,
    BaseButton,
    BaseBack,
    BaseLoader,
    CheckoutAcknowledgement,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const {
      isSplitEnabled,
      splitPaymentOrderAllowed,
      isBillPaymentsTemplate,
      isUserAddressEnabled,
    } = useProvider()
    const {
      payment,
      currentFlowType,
      isQuickBuyCurrentFlowType,
      getFlowTypeFromRoute,
    } = useCheckoutReactive()
    const { noBankAccounts, noCreditCards, fetchPaymentMethods } =
      useFlowPaymentMethods(currentFlowType.value, payment.value.payeeId)
    const { selectedCard, selectedBankAccount, isPayNow, unselectPayments } =
      useStatementAutoPayments()
    const { getCurrentFlowRouteName } = useCheckoutRoutes()
    const { verifyingCard } = useVerifyCard()
    const { addresses, defaultAddress, firstAddress, getAddresses } =
      useAddresses()
    const { fetchCurrentPurchases } = usePurchases()
    const { cartOrderAddresses } = useCart()
    const { withAccessCheck } = useAccess()
    const { fetchBalance: fetchPointsBalance } = usePoints()

    const backendErrors = ref({})
    const additionalPaymentMsg = reactive({
      show: true,
      message:
        'Please select an additional payment method to cover the payment total.',
    })

    const redirect = computed(() => ({
      name: 'home',
      ...route.meta?.back,
    }))
    const iSplitPaymentAllowed = computed(() => {
      const flowType = isQuickBuyCurrentFlowType.value
        ? FlowType.purchase
        : currentFlowType.value

      return splitPaymentOrderAllowed(flowType)
    })
    const processing = computed(() => {
      return verifyingCard.value || payment.value?.submitting
    })
    const isEmpty = computed(() => {
      return noCreditCards.value && noBankAccounts.value
    })
    const showAdditionalPaymentMsg = computed(() => {
      return (
        roundFigure(payment.value.selectedAmount) !== 0 &&
        roundFigure(payment.value.selectedAmount) <
          payment.value.subTotalWithProgramFees
      )
    })

    const billingAddress = computed(() =>
      (addresses.value ?? []).find(
        (address) => address.id === cartOrderAddresses.billing?.id
      )
    )

    const buttonLabel = computed(() => {
      if (payment.value.selectedPayId) {
        return 'Continue'
      } else {
        return payment.value.isScheduledPayment ? 'Schedule' : 'Pay'
      }
    })

    function onAddressAdd() {
      initAddress()
    }

    async function initAddress() {
      try {
        if (addresses.value.length > 0) {
          handlePayAddress()
        } else {
          await getAddresses()
          handlePayAddress()
          if (addresses.value.length === 0) {
            showAddAddress({ replace: true })
          }
        }
      } catch (error) {
        console.error(error)
      }
    }

    function showAddAddress({ replace = false }) {
      router.push({
        ...payment.value.getAddAddressRoute(),
        params: {
          replace,
        },
        replace,
      })
    }

    function handlePayAddress(address) {
      if (addresses.value.length === 0) return
      const selectedAddress =
        address || defaultAddress.value || firstAddress.value

      if (selectedAddress) {
        payment.value.address = selectedAddress
      }
    }

    function showPreviewModal(preview, { onConfirm, onCancel }) {
      if (preview.length > 0) {
        modal.show('checkout-preview', {
          props: {
            onCancel: () => {
              onCancel()
              router.replace({ name: 'home' })
            },
            onConfirm: async () => {
              try {
                await checkBankAccount()
                onConfirm()
              } catch (error) {
                console.error(error)
                payment.value.submitting = false
                return
              }
            },
          },
        })
      } else {
        onConfirm()
      }
    }

    async function onSubmit() {
      backendErrors.value = {}

      try {
        if (isQuickBuyCurrentFlowType.value) {
          const addressId = billingAddress.value?.id

          payment.value.billingAddressId =
            addressId?.toString() ??
            cartOrderAddresses.billing?.id.toString() ??
            payment.value.address?.id?.toString()

          payment.value.shippingAddressId = payment.value.hasDeliverableItems
            ? cartOrderAddresses.shipping?.id.toString() ??
              addressId?.toString() ??
              payment.value.address?.id?.toString()
            : null
        }

        await payment.value.checkout({
          onPreview: showPreviewModal,
          onComplete,
        })
      } catch (error) {
        Vue.notify({
          text: error,
          type: 'error',
          duration: 5000,
        })
      }
    }

    function checkBankAccount() {
      return new Promise((resolve, reject) => {
        if (payment.value.payWithBankAccount && !isBillPaymentsTemplate.value) {
          modal.show('confirm-pay-from-bank', {
            props: {
              onConfirm: () => {
                modal.hide('confirm-pay-from-bank')
                resolve()
              },
              onCancel: () => {
                reject(new Error('cancel'))
              },
            },
          })
        } else {
          resolve()
        }
      })
    }

    function processBackendErrors(error) {
      const { validationErrors } = error
      const backendErrors = {}

      if (!validationErrors) {
        return
      }

      for (const key in validationErrors) {
        const firstError = validationErrors[key][0]

        backendErrors[key] = firstError || ''
      }
      backendErrors.value = backendErrors
    }

    async function onComplete(result) {
      try {
        if (payment.value.selectedPayId) {
          router.push({
            name: getCurrentFlowRouteName('complete-payid'),
            params: {
              id: result.number,
              orderId: result.number,
            },
          })
          return
        } else if (
          ['completed', 'pending', 'processing', 'scheduled'].includes(
            result.status?.toLowerCase()
          )
        ) {
          await fetchCurrentPurchases()

          fetchPaymentMethods()

          emitter.emit('activity:refresh')
        }
        router.push({
          name: getCurrentFlowRouteName('checkout-confirmation'),
          params: {
            id: result.number,
            orderId: result.number,
          },
        })
      } catch (error) {
        processBackendErrors(error)
        const errorKeys = Object.keys(backendErrors.value)

        if (errorKeys.length) {
          Vue.notify({
            text: backendErrors.value[errorKeys]?.[0],
            type: 'error',
            duration: 5000,
          })
        }
      }
    }

    function addNewPaymentMethod() {
      router.push({
        hash: '#profile-payment-methods-add',
        params: {
          source: payment.value.orderType,
        },
      })
    }

    function onCancel() {
      payment.value.reset()
      router.push(redirect.value)
    }

    function handleBack() {
      payment.value.termsAccepted = false
      payment.value.submitting = false
    }

    function selectPaymentMethod(type, method) {
      const payload = {
        ...method,
        calculatedAmount: payment.value.subTotalWithProgramFees,
      }

      if (type === PaymentMethodType.creditCard) {
        payment.value.selectedBankAccount = null
        payment.value.selectedCard = payload
      } else if (type === PaymentMethodType.bankAccount) {
        payment.value.selectedCard = null
        payment.value.selectedBankAccount = payload
      }
    }

    async function init() {
      if (!payment.value.isReadyForPayment) {
        router.replace(redirect.value)
      } else {
        initAddress()
        fetchPointsBalance()

        if (selectedCard.value && !payment.value.selectedCard) {
          selectPaymentMethod(PaymentMethodType.creditCard, selectedCard.value)
        }
        if (selectedBankAccount.value && !payment.value.selectedBankAccount) {
          selectPaymentMethod(
            PaymentMethodType.bankAccount,
            selectedBankAccount.value
          )
        }
        await payment.value.getTransactionFees()

        emitter.on('addresses:add', onAddressAdd)
      }
    }

    onBeforeMount(async () => {
      currentFlowType.value = getFlowTypeFromRoute(route)

      if (isQuickBuyCurrentFlowType.value) {
        payment.value.initItems({
          type: route.query?.type ?? null,
          query: route.query ?? null,
        })
      }
      await init()
    })

    onBeforeUnmount(() => {
      emitter.off('addresses:add', onAddressAdd)
      unselectPayments()
      isPayNow.value = false
    })

    return {
      payment,
      isSplitEnabled,
      addresses,
      iSplitPaymentAllowed,
      processing,
      isEmpty,
      showAdditionalPaymentMsg,
      additionalPaymentMsg,
      redirect,
      showAddAddress,
      onSubmit,
      addNewPaymentMethod,
      onCancel,
      handleBack,
      init,
      formatDollar,
      currentFlowType,
      buttonLabel,
      isUserAddressEnabled,
      withAccessCheck,
    }
  },
}
</script>

<template>
  <div
    id="bill-payments-select-method"
    class="flex h-full w-full flex-col items-center overflow-hidden px-10 py-8"
  >
    <div class="w-full max-w-xl pb-8 pl-3 pr-6">
      <base-back
        v-analytics:click="{
          pageGroup: 'Make a payment',
          page: 'Checkout',
          cta: 'Back',
        }"
        class="block self-start leading-5"
        :to="redirect"
        @click="handleBack"
      />
    </div>

    <div class="w-full flex-1 overflow-auto">
      <base-loader
        v-if="payment.isQuickBuyInitializing"
        size="xl"
        class="h-full w-full"
      />
      <div v-else class="mx-auto flex w-full max-w-xl flex-col px-2">
        <div class="mb-2 flex w-full shrink-0 flex-wrap justify-between">
          <span class="text-2xl font-bold text-eonx-neutral-800">
            Payment options
          </span>
          <base-button
            v-if="!isEmpty && !isSplitEnabled && payment.canAddPaymentMethods"
            size="sm"
            @click="
              withAccessCheck({
                callback: addNewPaymentMethod,
                config: { actionType: 'paymentMethods' },
              })
            "
          >
            <span class="text-sm">+ Add payment method</span>
          </base-button>
        </div>
        <div v-if="iSplitPaymentAllowed" class="w-full text-eonx-neutral-600">
          Select your preferred payment method or points + pay.
        </div>

        <checkout-select-address
          v-if="isUserAddressEnabled"
          :show-addresses="payment.isAddressSelectionAllowed"
          class="mt-8"
        />

        <payment-methods-list class="mt-8" />
        <checkout-coupons
          v-if="payment.isCouponsAllowed"
          :flow-type="currentFlowType"
        />
      </div>
    </div>

    <div class="w-full max-w-xl px-2">
      <div class="w-full">
        <message-panel
          v-show="showAdditionalPaymentMsg && additionalPaymentMsg.show"
          class="w-full"
          type="warning"
          icon="heroicons/solid/exclamation-triangle"
          dont-close
          :icon-size="32"
        >
          {{ additionalPaymentMsg.message }}
        </message-panel>
      </div>

      <checkout-acknowledgement />

      <div class="flex w-full justify-center gap-2.5 sm:pt-4">
        <base-button
          v-analytics:click="{
            pageGroup: 'Make a payment',
            page: 'Checkout',
            cta: 'Cancel',
          }"
          look="outlined-color"
          class="flex-1"
          @click="onCancel"
        >
          Cancel
        </base-button>

        <base-button
          v-if="isUserAddressEnabled && addresses.length === 0"
          class="flex-1"
          :loading="processing"
          @click="showAddAddress"
        >
          Add address
        </base-button>

        <base-button
          v-else
          v-analytics:click="{
            pageGroup: 'Make a payment',
            page: 'Checkout',
            cta: buttonLabel,
          }"
          class="flex-1"
          :disabled="!payment.readyToPay || !payment.isTermsAccepted"
          :loading="payment.loadingFees || processing"
          @click="onSubmit"
        >
          {{ buttonLabel }}
        </base-button>
      </div>
    </div>
  </div>
</template>
