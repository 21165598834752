<script setup lang="ts">
import { ref, computed } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import CouponTag from '/~/components/checkout/components/checkout-coupon-tag.vue'
import { useCheckoutReactive } from '/~/composables/checkout'

const { payment } = useCheckoutReactive()

const useCoupons = ref(payment.value.hasCoupons)
const couponInput = ref('')

const isDisabled = computed(() => {
  return payment.value.submitting || payment.value.isCouponVerifying
})

async function onCouponAdd() {
  await payment.value.verifyPromotionCode(
    couponInput.value,
    payment.value.amount
  )

  couponInput.value = ''
}

async function onCouponRemove(coupon) {
  await payment.value.removeCartPromotionCode(coupon.promotionCode)

  useCoupons.value = payment.value.hasCoupons
}
</script>

<template>
  <div class="space-y-5">
    <base-checkbox
      v-model="useCoupons"
      data-testid="checkout-coupons-quick-buy"
      :disabled="payment.hasCoupons"
      look="v2"
    >
      Use coupon code
    </base-checkbox>

    <div
      v-if="useCoupons && !payment.hasCoupons"
      class="flex items-center rounded"
    >
      <base-input
        v-model="couponInput"
        placeholder="Coupon code"
        nolabel
        autofocus
        :disabled="isDisabled"
      >
        <template #inner-filter>
          <div class="px-5">
            <base-button
              size="xl"
              look="link"
              :loading="payment.isCouponVerifying"
              :disabled="isDisabled || !couponInput"
              @click="onCouponAdd"
            >
              <span
                class="font-normal"
                :class="{
                  'text-eonx-neutral-600': isDisabled || !couponInput,
                }"
              >
                Apply
              </span>
            </base-button>
          </div>
        </template>
      </base-input>
    </div>
    <div v-show="payment.hasCoupons" class="flex flex-wrap">
      <coupon-tag
        v-for="(item, idx) in payment.selectedCoupons"
        :key="idx"
        class="mb-2.5 mr-[5px]"
        @remove="onCouponRemove(item)"
      >
        {{ item.promotionCode }} {{ formatDollar(-item.amount) }}
      </coupon-tag>
    </div>
  </div>
</template>
