<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import { cardCode } from '/~/utils/cards'
import { createDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import { formatPoints } from '/~/utils/points'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseDatepicker from '/~/components/base/datepicker/base-datepicker.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseRadio from '/~/components/base/radio/base-radio.vue'
import { useCheckoutRoutes, useCheckoutReactive } from '/~/composables/checkout'
import { useLocalization } from '/~/composables/localization'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'
import { useScheduledPayments } from '/~/composables/scheduled-payments'
import SchedulerStatusAlert from '/~/templates/bill-payments/components/payments/scheduler-status-alert.vue'
import PaymentMethodBlockItem from '../payments-methods-block-item.v3.vue'
import PaymentMethodBlock from '../payments-methods-block.v3.vue'

const { getDatePickerFormat, translate } = useLocalization()
const router = useRouter()
const route = useRoute()
const {
  isScheduledPaymentDisabled,
  scheduledPaymentDateFrom,
  scheduledPaymentDateTo,
  dateToPayFormatted,
} = useScheduledPayments()
const { isMethodAvailable, loaded, points } = usePaymentMethods()
const { pointsBalance, pointsLabel } = usePoints()
const { getCurrentFlowRouteName } = useCheckoutRoutes()
const { splitPaymentOrderAllowed } = useProvider()

const { payment, currentFlowType } = useCheckoutReactive()

const isScheduledToggled = ref(payment.value.isScheduledPayment)

const isPointsAvailable = computed(
  () =>
    isMethodAvailable(PaymentMethodType.points, payment.value.orderType) &&
    points.value.length > 0
)

const isPointBalanceZero = computed(() => pointsBalance.value === 0)

const isPointsDisabled = computed(
  () => payment.value.loadingTransactionFees || isPointBalanceZero.value
)

const isPayIdSelected = computed(() => Boolean(payment.value.payWithPayId))
const isPointsSelected = computed(() => Boolean(payment.value.payWithPoints))
const isCreditCardSelected = computed(() => {
  return Boolean(payment.value.payWith?.card)
})
const isBankAccountSelected = computed(() =>
  Boolean(payment.value.payWithBankAccount)
)
const isMethodSelected = computed(
  () => isCreditCardSelected.value || isBankAccountSelected.value
)

const methodIcon = computed(() => {
  if (isCreditCardSelected.value) {
    return `billpay/payment-methods/${cardCode(payment.value.selectedCard)}`
  } else if (isBankAccountSelected.value) {
    return 'v2/custom/bank'
  }

  return null
})
const methodFirstRow = computed(() => {
  if (!isMethodSelected.value) {
    return null
  }

  const { name, type, accountName } = isCreditCardSelected.value
    ? payment.value.selectedCard
    : payment.value.selectedBankAccount

  switch (type) {
    case PaymentMethodType.creditCard:
      return name
    case PaymentMethodType.bankAccount:
      return accountName
    default:
      return ''
  }
})
const methodSecondRow = computed(() => {
  if (!isMethodSelected.value) {
    return null
  }

  const { number, type, accountNumber, bsb } = isCreditCardSelected.value
    ? payment.value.selectedCard
    : payment.value.selectedBankAccount

  switch (type) {
    case PaymentMethodType.creditCard:
      return `**** **** **** ${number.slice(-4)}`
    case PaymentMethodType.bankAccount:
      return translate('bankAccount.details', {
        acc: `*** *** *${accountNumber.slice(-2)}`,
        bsb,
      })
    default:
      return null
  }
})

const disablePaymentMethods = computed(
  () => payment.value.payWithPoints === payment.value.subTotalWithProgramFees
)

function onDateToPay(newDate) {
  payment.value.date = createDate(
    newDate,
    getDatePickerFormat('daymonthyearnumeric')
  )
}

function onPoints() {
  if (isPointBalanceZero.value) {
    return
  }

  router.push({
    name: getCurrentFlowRouteName('checkout-points'),
    query: route.query,
  })
}

function onMethod() {
  router.push({
    name: getCurrentFlowRouteName('checkout-method'),
    query: route.query,
  })
}

function onPointsCancel() {
  if (payment.value.selectedCard) {
    payment.value.selectedCard.calculatedAmount +=
      payment.value.selectedPoints.calculatedAmount
  } else if (payment.value.selectedBankAccount) {
    payment.value.selectedBankAccount.calculatedAmount +=
      payment.value.selectedPoints.calculatedAmount
  }

  payment.value.selectedPoints = null
  payment.value.getTransactionFees()
}

function onMethodCancel() {
  payment.value.selectedCard = null
  payment.value.selectedBankAccount = null
  payment.value.selectedPayId = null
  payment.value.getTransactionFees()
}

watch(isScheduledToggled, () => {
  if (!isScheduledToggled.value) {
    payment.value.date = null
  } else {
    onDateToPay(dateToPayFormatted.value)
  }
})

watch(
  isPointsSelected,
  () => {
    if (isPointsSelected.value) {
      isScheduledToggled.value = false
    }
  },
  {
    immediate: true,
  }
)

watch(
  isPayIdSelected,
  () => {
    if (isPayIdSelected.value) {
      isScheduledToggled.value = false
    }
  },
  {
    immediate: true,
  }
)
</script>

<template>
  <div>
    <payment-method-block
      v-if="splitPaymentOrderAllowed(currentFlowType) && isPointsAvailable"
      title="Pay with points"
      :loading="!loaded"
      class="mb-5 sm:mb-8"
    >
      <payment-method-block-item
        v-analytics:click="{
          pageGroup: 'Make a payment',
          page: 'Checkout',
          cta: pointsLabel,
        }"
        :is-disabled="isPointsDisabled"
        @click="onPoints"
      >
        {{ pointsLabel }}

        <template v-if="isPointsSelected" #subtitle>
          Using {{ formatPoints(payment.selectedPoints.usePoints) }} points
          <span class="hidden sm:inline">
            | Value {{ formatDollar(payment.payWithPoints) }}
          </span>
        </template>
        <template v-else-if="isPointBalanceZero" #subtitle>
          Not enough points for payment
        </template>

        <template #icon>
          <base-icon
            svg="symbion/token"
            :size="40"
            :class="{
              'text-primary': isPointsSelected,
            }"
          />
        </template>

        <template v-if="isPointsSelected" #action>
          <base-button
            v-analytics:click="{
              pageGroup: 'Make a payment',
              page: 'Checkout',
              label: 'Points unselected',
            }"
            icon="close-circle"
            class="text-eonx-neutral-600"
            :size="26"
            @click.stop="onPointsCancel"
          />
        </template>
      </payment-method-block-item>
    </payment-method-block>

    <payment-method-block
      title="Payment method"
      :loading="!loaded"
      class="mb-8"
    >
      <payment-method-block-item
        v-if="isPayIdSelected"
        v-analytics:click="{
          pageGroup: 'Make a payment',
          page: 'Checkout',
          cta: 'PayId',
        }"
        :is-disabled="payment.loadingTransactionFees"
        @click="onMethod"
      >
        PayID

        <template v-if="isPayIdSelected" #subtitle>
          {{ payment.selectedPayId.reference }}
        </template>

        <template #icon>
          <base-icon svg="billpay/payment-methods/payid" :size="40" />
        </template>

        <template v-if="isPayIdSelected" #action>
          <base-button
            v-analytics:click="{
              pageGroup: 'Make a payment',
              page: 'Checkout',
              label: 'Points unselected',
            }"
            icon="close-circle"
            class="text-eonx-neutral-600"
            :size="26"
            @click.stop="onMethodCancel"
          />
        </template>
      </payment-method-block-item>

      <payment-method-block-item
        v-else-if="
          !splitPaymentOrderAllowed(currentFlowType) && isPointsSelected
        "
        v-analytics:click="{
          pageGroup: 'Make a payment',
          page: 'Checkout',
          cta: pointsLabel,
        }"
        :is-disabled="payment.loadingTransactionFees"
        @click="onMethod"
      >
        {{ pointsLabel }}

        <template #subtitle>
          Using {{ formatPoints(payment.selectedPoints.usePoints) }} points
          <span class="hidden sm:inline">
            | Value {{ formatDollar(payment.payWithPoints) }}
          </span>
        </template>

        <template #icon>
          <base-icon
            svg="symbion/token"
            :size="40"
            :class="{
              'text-primary': isPointsSelected,
            }"
          />
        </template>

        <template v-if="isPointsSelected" #action>
          <base-button
            v-analytics:click="{
              pageGroup: 'Make a payment',
              page: 'Checkout',
              label: 'Points unselected',
            }"
            icon="close-circle"
            class="text-eonx-neutral-600"
            :size="26"
            @click.stop="onPointsCancel"
          />
        </template>
      </payment-method-block-item>

      <payment-method-block-item
        v-else
        v-analytics:click="{
          pageGroup: 'Make a payment',
          page: 'Checkout',
          cta: 'Select or add payment method',
        }"
        :is-disabled="payment.loadingTransactionFees || disablePaymentMethods"
        @click="onMethod"
      >
        <template v-if="isMethodSelected">{{ methodFirstRow }}</template>
        <template v-else>Select or add payment method</template>

        <template v-if="isMethodSelected" #subtitle>
          {{ methodSecondRow }}
        </template>

        <template #icon>
          <div
            class="flex h-full w-full items-center justify-center rounded-full"
            :class="{
              'bg-eonx-neutral-600 text-white': isBankAccountSelected,
              'text-primary': !isMethodSelected,
            }"
          >
            <base-icon
              :svg="isMethodSelected ? methodIcon : 'v2/heroic/credit-card'"
              :size="
                isCreditCardSelected ? '3xl' : isBankAccountSelected ? 32 : 48
              "
            />
          </div>
        </template>

        <template v-if="isMethodSelected" #action>
          <base-button
            v-analytics:click="{
              pageGroup: 'Make a payment',
              page: 'Checkout',
              label: 'Payment method unselected',
            }"
            icon="close-circle"
            class="text-eonx-neutral-600"
            :size="26"
            @click.stop="onMethodCancel"
          />
        </template>
      </payment-method-block-item>
    </payment-method-block>

    <div v-if="payment.isSchedulingAllowed" class="mb-8 hidden sm:block">
      <div class="mb-6">
        <div class="flex items-center text-lg font-bold text-eonx-neutral-800">
          <span>Select date</span>
        </div>
      </div>
      <div class="ml-2.5 flex space-x-5">
        <base-radio
          v-model="isScheduledToggled"
          v-analytics:input="{
            pageGroup: 'Make a payment',
            page: 'Checkout',
            label: 'Pay now selected',
          }"
          :checked="!isScheduledToggled"
          :value="false"
          :disabled="payment.submitting"
        >
          Pay now
        </base-radio>
        <base-radio
          v-model="isScheduledToggled"
          v-analytics:input="{
            pageGroup: 'Make a payment',
            page: 'Checkout',
            label: 'Schedule for later selected',
          }"
          :checked="isScheduledToggled"
          :value="true"
          :disabled="isScheduledPaymentDisabled || payment.submitting"
        >
          Schedule for later
        </base-radio>
      </div>

      <scheduler-status-alert class="mt-6" />

      <div v-if="isScheduledToggled" class="mt-5">
        <base-datepicker
          v-model="dateToPayFormatted"
          v-analytics:input.custom="{
            pageGroup: 'Make a payment',
            page: 'Checkout',
            label: 'Set payment date',
          }"
          label="Date to pay"
          icon="v2/custom/calendar"
          :placeholder="getDatePickerFormat('daymonthyearnumeric')"
          icon-class="text-inherit"
          format="daymonthyearnumeric"
          :popup-style="{
            'z-index': 1000,
          }"
          :disabled-dates="{
            to: scheduledPaymentDateFrom,
            from: scheduledPaymentDateTo,
          }"
          :min-date-mobile="
            scheduledPaymentDateFrom.toISOString().split('T')[0]
          "
          :max-date-mobile="scheduledPaymentDateTo.toISOString().split('T')[0]"
          :disabled="payment.submitting"
          @input="onDateToPay"
        />
      </div>
    </div>
  </div>
</template>
